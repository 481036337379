<template>
  <div id="homepage">
    <div id="title">
      <div id="headers">
        <h1>Willkommen zur <b>Kaindorf Games</b><br/> Turnier Platform!</h1>
        <p>Hier findest du alle laufenden Turniere. Melde dich gleich an!</p>
      </div>
      <div id="buttons">
        <PlainButton :is-small="true" :scale-by-child="true" :button-function="()=>openUrl('/register')" v-if="user===null||user===undefined" button-type="fluid">Registrieren
        </PlainButton>
        <PlainButton :is-small="true" :scale-by-child="true" :button-function="()=>openUrl('/tournaments')" >Laufende
          Turniere
        </PlainButton>
      </div>
      <div class="material-icons" id="downArrow" >
        expand_more
      </div>
    </div>
<!--    <div id="us">
      <div id="us-text">
        <div id="us-header">
          <h2>Was machen wir?</h2>
          <p>Wir veranstalten die Kaindorf Games, eine Reihe an E-Sports Events, die mehrmals im Jahr stattfinden. Ob Anfänger oder Profi, alle sind bei uns willkommen um Spaß zu haben. Mögen die Spiele beginnen! </p>
        </div>
        <div>
          <PlainButton :is-small="false" :scale-by-child="true" :button-function="()=>openUrl('/about',openUrl,false)">Über uns</PlainButton>
        </div>
      </div>
      <div id="illustration" >
        <img src="../assets/illustrations/Controller.svg" alt="Edward">
      </div>
    </div>-->
    <div id="tutorial">
      <h2>Wie funktioniert es?</h2>
      <div id="cards">
        <TutorialCard title-description="Schritt 1:" title="Discord" button-text="Discord"
                      icon="voice_chat" :button-function="()=>openUrl('https://discord.gg/GkR9HSxbve',true,true)">
          Joine unserem Discord um Informationen zu den Spielen zu erhalten.
        </TutorialCard>
        <TutorialCard title-description="Schritt 2:" title="Registrieren" button-text="Anmelden"
                      icon="how_to_reg" :button-function="()=>openUrl('/register')">
          Melde dich hier auf der Website an, um dich für Spiele einzuschreiben.
        </TutorialCard>
        <TutorialCard title-description="Schritt 3:" title="Einschreiben" button-text="Turniere"
                      icon="sports_esports" :button-function="()=>openUrl('/tournaments')">
          Melde dich mit deinem Team zu den Spielen an oder trete einem Team bei.
        </TutorialCard>
      </div>
    </div>

    <div id="socialmedia">
      <h2>Hier findet ihr uns</h2>
      <div class="wrapper" id="socialCard">
        <SocialMediaCard id="one" title="Discord" picture="discord.svg"
                         color="rgba(86, 88, 239, 0.10)" button-text="Discord" url="https://discord.gg/GkR9HSxbve"
                         text-color="rgba(86, 88, 239, 1)">
          Um immer am laufenden zu bleiben und nichts Wichtiges zu verpassen, solltet ihr unserem Discord Server joinen! Außerdem funktioniert hier die Kommunikation auch am besten.        </SocialMediaCard>

        <SocialMediaCard id="two" title="Youtube" picture="youtube.svg" color="rgba(255, 0, 0, 0.10)"
                         text-color="rgba(255, 0, 0, 1)" button-text="Youtube"
                         url="https://www.youtube.com/channel/UCvc2hxsjl5WbYcbD0mvbmlw">
          Hier findet ihr Livestreams zu den spielen, die aktuell laufen, sollte irgendwer ganz neugierig sein, könnt ihr auch vergangene Streams ansehen!        </SocialMediaCard>

        <SocialMediaCard id="three" title="Twitter" picture="twitter.svg" color="rgba(29, 155, 240, 0.10)"
                         text-color="rgba(29, 155, 240, 1)" button-text="Twitter"
                         url="https://twitter.com/KaindorfGames">
          Um während den Games die Ergebnisse mitverfolgen zu können, gibt es auch einen Twitter-Account.
        </SocialMediaCard>

        <SocialMediaCard id="four" title="Instagram" picture="instagram.png" color="rgba(221, 66, 128, 0.10)"
                         text-color="rgba(221, 66, 128, 1)" button-text="Instagram"
                         url="https://www.instagram.com/kaindorfgames/">
          Ihr wollt mehr über uns und unsere Ideen zu den Spielen wissen und zusätzlich auch noch Infos und Content zu den aktuellen Games bekommen? Dann schaut auf unserem Instagram-Account vorbei!        </SocialMediaCard>
      </div>
    </div>
    <div>
      <a :href="landingPageURL+'/imprint'" class="legal">Impressum</a>
    </div>

  </div>
</template>

<script>
import TutorialCard from "@/components/Cards/TutorialCard";
import SocialMediaCard from "@/components/Cards/SocialMediaCard";
import {mapState} from "vuex";
import PlainButton from "@/components/Buttons/PlainButton";

export default {
  name: "TestPage",
  components: {PlainButton, SocialMediaCard, TutorialCard},
  data(){
    return{
      landingPageURL:"/",
    }
  },
  computed:{
    ...mapState('account',['user'])
  },
  created() {
    this.landingPageURL=window["VUE_APP_LANDING_PAGE_URL"] || "https://kaindorf-games.at"
  },

  methods: {
    openUrl(link, external = false, newTab=false) {
      if (external) {
        window.open(link, newTab?'_blank':'_self')
      } else {
        this.$router.push(link)
      }
    }
  }
}

</script>

<style lang="scss" scoped>
@import "../styles/themes";

h1 {
  margin-top: 0;
}

.legal{

  @include theme{
    color: theme-get("text-1")
  }
  &:visited{
    @include theme{
      color: theme-get("text-1")
    }
  }
  &:hover{
    @include theme{
      background-color: theme-get("primary-background");
      color: theme-get("primary-color")
    }
  }
  width: 100%;
  padding: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
#title {
  #downArrow{
    position: absolute;
    left: 50%;
    bottom: 24px;
    font-size: 56px;
    transform: translateX(-50%);
    @include theme{
      color: theme-get("text-2")
    }
  }
  text-align: left;
  padding: 64px 256px;
  @include theme {
    background-color: theme-get("background-color-2");
  }
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100%;

  h1 {
    @include theme {
      color: theme-get("header-1");
    }
    margin-bottom: 0;
    font-size: 5em;
    font-weight: 600;
    line-height: 1.1;
  }

  p {
    @include theme {
      color: theme-get("header-3");
    }
    font-size: 1.5em;
  }

  #buttons {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
}

#us {
  min-height: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 64px 256px;
  @include theme {
    background-color: theme-get("background-color-1");
  }

  #us-text {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
  }

  #illustration {
    padding-left: 128px;

    img {
      height: 384px;
    }
  }

  h2 {
    margin-top: 0;
    @include theme {
      color: theme-get("header-1");
    }
    margin-bottom: 0;
    font-size: 3em;

    font-weight: 600;
    line-height: 1.1;
  }

  p {
    @include theme {
      color: theme-get("text-2");
    }
    line-height: 2;
    font-size: 1.25em;
  }

}

#tutorial {
  min-height: 100%;
  height: auto;
  padding: 64px 256px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @include theme {
    background-color: theme-get("background-color-2");
  }

  h2 {
    margin-top: 0;
    @include theme {
      color: theme-get("header-1");
    }
    margin-bottom: 64px;
    font-size: 3em;

    font-weight: 600;
    line-height: 1.1;

  }

  #cards {
    display: flex;
    justify-content: center;
    gap: 32px;
    flex-wrap: wrap;
    width: auto;
  }
}

#socialmedia {
  padding: 64px 324px;
  min-height: 100%;
  height: auto;
  @include theme() {
    background-color: theme-get('background-color-2');
  }
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h2 {
    margin-top: 0;
    @include theme {
      color: theme-get("header-1");
    }

    margin-bottom: 64px;
    font-size: 3em;

    font-weight: 600;
    line-height: 1.1;

  }
  .wrapper {
    display: grid;
    grid-template-columns:repeat(5, 1fr);
    grid-template-areas:
  "lefttop lefttop lefttop righttop righttop"
  "leftbottom leftbottom rightbottom rightbottom rightbottom";
    grid-gap: 48px;
    grid-template-rows:auto;
    justify-items: stretch;
    #one {
      grid-area: lefttop;
      width: 100%;
      height: 100%;

      justify-self: stretch;
    }

    #two {
      width: 100%;
      height: 100%;
      grid-area: righttop;
    }

    #three {
      width: 100%;
      height: 100%;
      grid-area: leftbottom;
      justify-self: stretch;
    }

    #four {
      width: 100%;
      height: 100%;
      grid-area: rightbottom;
      justify-self: stretch;
    }
  }

}




@media screen and(max-width: 1400px) {
  #us {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    #us-text {
      align-items: flex-start;
    }

    #illustration {
      padding: 0;

      img {
        height: 384px;
      }
    }
  }
  #tutorial {
    padding: 64px 128px;
  }
  #socialmedia{
    .wrapper{
      grid-gap:32px;
    }
    padding: 64px 192px;
  }
}

@media screen and (max-width: 1024px) {
  #title {
    padding: 64px 128px;

    h1 {
      font-size: 3em;
    }

    p {
      font-size: 1.25em;
    }
  }
  #us {
    justify-content: center;
    padding: 64px 128px;

    #illustration {
      img {
        height: 256px;
      }
    }
  }
  #socialmedia{
    .wrapper{
      grid-gap:24px
    }
    padding: 64px;
  }
}

@media screen and (max-width: 768px) {
  #title {

    #downArrow{
      bottom: 24px;
    }

    padding: 64px;

    h1 {
      font-size: 2.5em;
    }

    p {
      font-size: 1em;
    }

    #buttons {
      flex-direction: column-reverse;
      gap: 8px;
    }
  }
  #us {
    padding: 64px 64px;
    flex-direction: column-reverse;
    h2 {
      font-size: 2em;
    }

    p {
      font-size: 1em;
    }

    #illustration {
      align-self: flex-start;
    }
  }
  #tutorial {
    padding: 64px 64px;
    h2{
      font-size:2em;
    }
  }
  #socialmedia{
    .wrapper{
      grid-template:
    'lefttop'
    'righttop'
    'leftbottom'
    'rightbottom';
    }
    padding: 64px;
  }
}
@media screen and (max-width: 480px) {
  #title {
    padding: 32px;
    #downArrow{
      font-size: 48px;
      bottom:24px;
    }
  }
  #tutorial {
    padding: 64px 32px;
  }
}
</style>
