<template>
  <button id="fluidColorButton" @click.prevent="buttonFunction" :class="{'small':isSmall,  'childScale':scaleByChild,'slim':isSlim}" :style="{'--bg-Color':bgColor,'--text-Color':textColor }">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "ColoredFluidButton",

  props: {
    bgColor: {
      type:String,
      default:"rgba(255,255,255,0.15)",
    },
    textColor:{
      type:String,
      default:"rgba(255,255,255,0.15)"
    },
    buttonFunction:Function,
    isSlim: {
      type:Boolean,
      default:false
    },
    isSmall: {
      type:Boolean,
      default:false
    },
    scaleByChild: {
      type:Boolean,
      default:false
    }
  }
}
</script>

<style lang="scss" scoped>

@import "src/styles/themes";
.childScale{
  max-width: max-content !important;
}

.slim{padding-top:8px !important; padding-bottom: 8px !important;}
.small{padding:16px 24px !important;}

#fluidColorButton{

  background-color: var(--bg-Color);
  color: var(--text-Color);
  box-sizing: border-box;
  transition: 0.1s;
  cursor: pointer;
  user-select: none;
  border:none;
  width: 100%;
  padding: 16px 64px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 1em;
  &:hover{
    transform: scale(1.05);
  }
  &:active{
    transform: scale(0.95);
  }
}
</style>
