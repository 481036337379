<template>
  <div id="profile">
    <router-view v-slot="{Component}">
        <component :is="Component"></component>
    </router-view>
  </div>

</template>

<script>
export default {
  name: "Profile"
}
</script>

<style scoped>
    #profile{
      box-sizing: border-box;
      position: relative;
      height: 100%;
    }
</style>