<template>
  <div id="participations">
    <div>
      <div id="header">
        <div id="breadcrumbs">
          <router-link to="/profile">Profil</router-link>
          <div class="material-icons">chevron_right</div>
          <router-link to="/profile/participations">Teilnahmen</router-link>
        </div>
        <h1>Meine Teilnahmen</h1>
      </div>
      <div id="listContainer">
        <transition name="list">
          <FullscreenLoading v-if="alertType==='alert-loading'">
          </FullscreenLoading>
          <div v-else-if="teams?.length>0">
            <div v-for="team in teams" :key="team.team_id" class="participation">
              <h4>{{ team.tournament.game.full_name }}</h4>
              <TeamItem :team="team"
                              :team_size="team.tournament.team_size"
                              :is-in-team="team.team_mates.some(value=>value.user.user_id===user.userdata.user_id)"
                              :leave-function="leaveTeamWarning"
                              :is-private="team.has_password"
                        :go-to-team-view="() => goToTeamView(team.tournament_id, team.tournament.event_id)"></TeamItem>
            </div>
          </div>

          <EmptyList v-else>
            <template v-slot:title>
              Keine Teilnahmen
            </template>
            <template v-slot:description>
              Du nimmst noch an keinen Turnier Teil.
            </template>
          </EmptyList>

        </transition>
      </div>


      <transition name="dialogBackground">
        <div class="dialogBackground" v-if="leaveWarningDialogVisible"
             @click="()=>{leaveWarningDialogVisible=false}"></div>
      </transition>
      <transition name="dialog">
        <ErrorDialog button-text="Verlassen" :is-warning="true" :show-close-button="true" dialog-title="Bist du sicher?"
                     :close-function="()=>{leaveWarningDialogVisible=false}" :confirm-function="()=>this.leaveTeam()"
                     v-if="leaveWarningDialogVisible">Bist du sicher, dass du dein Team verlassen willst?
        </ErrorDialog>
      </transition>
      <transition name="error-show">
        <ErrorPop v-if="alertType==='alert-danger'" :error-text="alertMessage" />
      </transition>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {teamService} from "@/services";
import ErrorPop from "../../components/Popups/ErrorPop";
import ErrorDialog from "../../components/Dialogs/ErrorDialog";
import FullscreenLoading from "../../components/Indicators/Loading/FullscreenLoading";
import EmptyList from "@/components/List/EmptyList";
import TeamItem from "@/components/Tournaments/TeamItem";

export default {
  name: "Participations",
  inheritAttrs: true,
  components: {TeamItem, EmptyList, FullscreenLoading, ErrorPop, ErrorDialog},
  computed: {
    ...mapState('account', ['user']),
    ...mapState('alert', {
          alertMessage: 'message',
          alertType: 'type'
        }
    )
  },
  created() {
    if (this.user !== null) {
      this.getUserData();
    }
    this.getTeams()
  },
  data() {
    return {
      teams: [],
      leaveTeamID: 0,
      leaveWarningDialogVisible: false,
    }
  },
  methods: {
    ...mapActions('account', ['getUserData']),
    getTeams() {
      teamService.fetchTournaments().then(event => {
        teamService.fetchUserTeams().then(teams => {
          this.teams = teams.filter(t => t.tournament.event_id === event.event_id)
        })
      });
    },
    leaveTeamWarning(team) {
      if (this.user !== null) {
        this.leaveTeamID = team.team_id;

        this.leaveWarningDialogVisible = true
      }
    },
    leaveTeam(){
      teamService.leaveTeam(this.leaveTeamID).then(() => {
            this.leaveTeamID = 0;
            this.leaveWarningDialogVisible = false
            this.getTeams()
          }
      )
    },
    goToTeamView(tournamentId) {
      this.$router.push('/team/'+tournamentId);
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/themes";

.error-show-enter-active, .error-show-leave-active {
  transition: all 0.25s;
}

.error-show-enter-from,
.error-show-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.dialogBackground-enter-active, .dialogBackground-leave-active {
  transition: opacity 0.25s;
}

.dialogBackground-enter-from, .dialogBackground-leave-to {
  opacity: 0;
}

.dialog-enter-active, .dialog-leave-active {
  transition: 0.25s ease;
}

.dialog-enter-to, .dialog-leave-from {
  transform-origin: center;
  transform: translateX(-50%) translateY(-50%) scale(1);
}

.dialog-enter-from, .dialog-leave-to {
  transform-origin: center;
  transform: translateX(-50%) translateY(-50%) scale(0);
}


.dialogBackground {
  z-index: 100;
  position: fixed;
  top: 0;
  background-color: hsla(0, 0%, 0%, 0.5);
  backdrop-filter: blur(50px);
  width: 100%;
  height: 100%;
}

.list-enter-active, .list-leave-active {

  transition: 0.25s;
}

.list-enter-from, .list-leave-to {
  opacity: 0;
}

#listContainer{
  padding: 64px 200px;
  position: relative;
  margin: auto;
  box-sizing: border-box;
  min-height: 100%;

  /*@include theme {
    background-color: theme-get('background-color-2');
  }*/
}

#participations {

  position: relative;
  min-height: 100%;
  height: auto;

  background-color: white;
  box-sizing: border-box;

  .participation {
    padding-bottom: 32px;
  }

  h1 {
    margin: 0;
    font-weight: 600;
    @include theme {
      color: theme-get('header-1');
    }
    font-size: 5em;
    text-align: left;
  }

  @include theme {
    background-color: theme-get('background-color-2');
  }

  h4 {
    margin: 0;
    @include theme {
      color: theme-get('listheader');
    }
    font-weight: 500;
    text-align: left;
    font-size: 2em;
  }

  #header {
    position: relative;
    padding: 48px 200px;
    @include theme {
      background-color: theme-get('background-color-1');
    }
  }

  #breadcrumbs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include theme {
      color: theme-get('text-2');
    }

    a {
      font-size: 1.0625em;
      @include theme {
        color: theme-get('text-2');
      }
    }
  }
}

@media screen and(max-width: 1024px) {
  #participations {
    #header {
      padding: 64px 64px;
    }

    h1 {
      line-height: 125%;
    }
  }

}

@media screen and (max-width: 768px) {
  #participations {
    #header {
      h1 {
        font-size: 3em;
      }
    }

  }
}

@media screen and (max-width: 512px) {
  #participations {
    #listContainer {
      padding: 32px;

    }
    #header {
      padding: 48px;

      h1 {
        text-align: center;
        font-size: 2.5em;
      }

      #breadcrumbs {
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 512px) {

}
</style>
