<template>
  <div class="teamDirectJoin">
    <FullscreenLoading v-if="alertType==='alert-loading'">
    </FullscreenLoading>
    <h1>Team Direkt Joinen</h1>
    <TextInput :input-title="'Dein ' + (this.tournament.ingame_name_source??'Ingame')+' Name'"
               v-model:input-text="fields.ingameName.text"
               :inputPlaceholder="fields.ingameName.placeholder" :textFieldType="fields.ingameName.type"
               :validationFunction="fields.ingameName.validFunction" :doValidation="fields.ingameName.useValidation"
               :ref="(el)=>setDirectJoinRefs(el,fields.ingameName.id)"></TextInput>
    <div id="ageCheck" v-if="tournament?.minimum_age">
      <Checkbox :is-active="isOld" :click-function="() => { toggleOld() }">

      </Checkbox>
      <p>Ich bestätige hiermit, dass mindestens {{ tournament.game?.minimum_age }} Jahre alt bin. </p>
    </div>
    <div class="actions">
      <PlainButton button-text="Abbrechen" :button-function="closeFunction" button-type="fluid">
        Abbrechen
      </PlainButton>
      <PlainButton :button-function="joinTeam" button-type="normal">
        Bestätigen
      </PlainButton>
    </div>
  </div>

  <transition name="error-show">
    <ErrorPop :error-text="alertMessage" v-if="alertType==='alert-danger'"></ErrorPop>
  </transition>
</template>

<script>
import PlainButton from "../../components/Buttons/PlainButton";
import {teamService} from "../../services";
import TextInput from "../../components/Inputs/TextInput";
import {mapActions, mapState} from "vuex";
import Checkbox from "@/components/Inputs/Checkbox";
import FullscreenLoading from "../../components/Indicators/Loading/FullscreenLoading";
import ErrorPop from "../../components/Popups/ErrorPop";

export default {
  name: "TeamDirectJoin",
  inheritAttrs: false,
  components: {
    PlainButton,
    TextInput,
    ErrorPop,
    FullscreenLoading,
    Checkbox
  },

  mounted() {
    teamService.fetchTournaments().then(event => this.tournament = event.tournaments.find(t => t.tournament_id === this.tournamentId));


  },

  data() {
    return {
      refs: {},
      ingame_name_source: "Ingame",
      isOld: false,
      tournament: {},
      fields: {
        ingameName: {
          label: 'Dein Name',
          text: '',
          id: 'ingame',
          placeholder: '',
          type: 'text',
          validFunction(text) {
            return text.length === 0 ? {
              isInvalid: true,
              errorText: 'Dein Ingame Name'
            } : {
              isInvalid: false,
              errorText: ''
            };
          },
          useValidation: true
        },
      }
    }
  },
  computed: {
    ...mapState('alert', {
          alertMessage: 'message',
          alertType: 'type'
        }
    ),
    errorMessage() {
      return this.ingame_name_source + ' Name eingeben';
    },
    tournamentId() {
      return parseInt(this.joinCode.split(".")[0].split("-")[1]);
    },
    joinCode() {
      return this.$route.query.join_code.toString()
    }
  },
  methods: {
    ...mapActions('alert', ["error"]),
    joinTeam() {
      if (this.validateTexts()) {
        let player_name = this.fields.ingameName.text
        if (!this.tournament.game.minimum_age || this.isOld) {
          teamService.joinTeamDirect({'player_name': player_name}, this.joinCode).then(
              teamMate => {
                this.closeFunction();
                return teamMate;
              },
              error => {
                this.error(error);
              }
          )
        }

      }
    },
    setDirectJoinRefs(el, name) {
      if (el) {
        this.refs[name] = el;
      }
    },

    toggleOld() {
      this.isOld = !this.isOld
    },
    closeFunction() {
      this.$router.push("/team/" + this.tournamentId);
    },
    validateTexts() {
      let isValid = true;
      Object.values(this.fields).forEach(field => {
        this.refs[field.id].validate(field.text)
        isValid = !this.refs[field.id].isInvalid && isValid;
      });
      return isValid;
    }
  },


}

</script>

<style lang="scss" scoped>
@import "src/styles/themes";

.error-show-enter-active, .error-show-leave-active {
  transition: all 0.25s;
}

.error-show-enter-from,
.error-show-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.teamDirectJoin {
  overflow-y: auto;
  box-shadow: 0 5px 20px rgba(0, 0%, 0%, 0.15);
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 32px;
  align-items: flex-start;
  z-index: 101;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: auto;

  @include theme() {
    background-color: theme-get('background-color-2');
  }
  border-radius: 16px;

  h1 {
    @include theme() {
      color: theme-get('header-1')
    }
    margin-top: 0;
    line-height: 1.05;
    font-size: 3em;
    font-weight: 600;
  }

  .actions {
    margin-top: 48px;
    display: flex;
    gap: 16px;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
  }

  #ageCheck {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 100%;


    p {


      @include theme() {
        color: theme-get('text-1');
      }
      margin: 0;
      text-align: left;
    }
  }


}

@media screen and (max-width: 768px) {
  .teamDirectJoin {
    align-items: center;

    h1 {
      font-size: 2em;
    }

    .actions {
      margin-top: 16px;
      flex-direction: column-reverse;
    }
  }


}
</style>