<template>
  <div id="profile">
    <FullscreenLoading v-if="alertType==='alert-loading'">
    </FullscreenLoading>
    <section id="header" >
      <div>
        <h1>Mein Profil</h1>
        <PlainButton button-type="fluid" :button-function="()=>openUrl('/profile/participations')" :is-slim="true" :scale-by-child="true">Teilnahmen</PlainButton>
      </div>
      <div id="resetButtons" v-if="!user?.userdata?.verified">
        <ColoredFluidButton bg-color="rgba(233, 109, 109, 0.15)" text-color="rgba(233, 109, 109, 1)" is-small  :button-function="()=>resendMessage('email')" v-if="!user?.userdata?.email_is_verified">
          Verifikations-Mail neu senden
        </ColoredFluidButton>
        <ColoredFluidButton bg-color="rgba(86, 88, 239, 0.15)" text-color="rgba(86, 88, 239, 1)" is-small  :button-function="()=>resendMessage('discord')" v-if="!user?.userdata?.discord_is_verified">
          Discord Verfikationsnachricht neu senden
        </ColoredFluidButton>
      </div>
    </section>
    <section id="userActions">
      <ProfileEditCard :username-prop="user?.userdata?.user_name" :email-prop="user?.userdata?.email" :discord-prop="user?.userdata?.discord_name"></ProfileEditCard>

    </section>
    <transition name="error-show">
      <ErrorPop :error-text="alertMessage" v-if="alertType==='alert-danger'"></ErrorPop>
    </transition>
    <transition name="error-show">
      <SuccessPop :error-text="alertMessage" v-if="alertType==='alert-success'"></SuccessPop>
    </transition>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import ProfileEditCard from "../../components/Cards/ProfileEditCard";
import ErrorPop from "@/components/Popups/ErrorPop";
import FullscreenLoading from "@/components/Indicators/Loading/FullscreenLoading";
import SuccessPop from "@/components/Popups/SuccessPop";
import ColoredFluidButton from "@/components/Buttons/ColoredFluidButton";
import {userService} from "@/services";
import PlainButton from "@/components/Buttons/PlainButton";
export default {
  name: "User",
  components: {PlainButton, ColoredFluidButton, ProfileEditCard, FullscreenLoading, ErrorPop,SuccessPop},
  computed:{
    ...mapState("account",["user"]),
    ...mapState('alert', {
      alertMessage: 'message',
      alertType: 'type'
    })
  },
  methods:{
    ...mapActions("account",["getUserData"]),
    openUrl(link, external=false){
      if(external){
        window.open(link,'_self')
      }else{
        this.$router.push(link)
      }
    },
    resendMessage(type){
      userService.resendVerification(type);
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/themes";
.error-show-enter-active, .error-show-leave-active {
  transition: all 0.25s;
}

.error-show-enter-from,
.error-show-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
#resetButtons{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap:16px;
}
#profile{
  position: relative;
  min-height: 100%;
  box-sizing: border-box;
  @include theme{
    background-color: theme-get('background-color-2');
  }

  #header{
    display: flex;
    justify-content: space-between;

  }
  section{
    padding: 48px 200px ;
    @include theme{
      background-color: theme-get('background-color-1');
    }
  }
  h1{
    @include theme{
      color: theme-get('header-1');
    }
    text-align: left;
    font-size: 5em;
    font-weight: 600;
    margin:0;
  }

  #user-information{
    display: grid;
    grid-template-columns: 500px auto;
    grid-template-rows: auto;
    grid-template-areas:
        "userform gamenames";
    grid-column-gap: 64px;
  }
  #userInfoForm{
    grid-area: userform;
  }

  #userActions{
    padding: 24px 200px ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include theme{
      background-color: theme-get('background-color-2');
    }

  }
}

@media screen and (max-width: 1400px) {
  #resetButtons{
    margin-top:64px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  #profile{
    #header{
      flex-direction: column;
      align-items: flex-start;

    }
  }


}
@media screen and (max-width: 1024px){
  #profile{

    #header{
      padding: 64px 128px;
      flex-direction: column;
      align-items: flex-start;

    }
  }
  #resetButtons{
    margin-top:64px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media screen and (max-width: 768px){

  #profile{
    #header{
      padding:64px 64px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      div{
        h1{
          text-align: center;
          font-size: 3em;
          line-height: 125%;
          margin:24px;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      #resetButtons{
        flex-shrink: 1;
        margin-top:48px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }
    #userActions{
      padding:48px 48px;
    }
  }
}

@media screen and (max-width: 512px){
  #profile{
    #header{
      padding:48px 48px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h1{
        text-align: center;
        font-size: 3em;
        line-height: 125%;
        margin:24px;
      }
    }
    #userActions{
      padding:48px 32px;
    }
  }
}

</style>
