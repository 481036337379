<template>
<div id="registerComplete">
  <div class="info">
    <img src="../../assets/icons/check.svg" class="check">
    <div class="verifyText">
      <h1>Perfekt!</h1>
      <div>
        Dein Account ist registriert.
      </div>
        Vergiss nicht, dich per Mail und per Discord(falls angegeben) zu verifizieren.<br/>
        Wenn du keine Mail/keine Nachricht erhalten hast,<br/> versuche unter "Profil" eine neue Nachricht anzufordern
    </div>
    <PlainButton :button-function="navigateLogin" scale-by-child >
      Zum Login
    </PlainButton>
  </div>

</div>
</template>

<script>
import PlainButton from "@/components/Buttons/PlainButton";
export default {
  name: "RegisterComplete",
  components: {PlainButton},
  methods:{
    navigateLogin(){
      this.$router.push('/login')
    }
  },
  inheritAttrs:false
}
</script>

<style lang="scss" scoped>
@import "src/styles/themes";
*{
  font-family: 'Poppins', sans-serif;

}
  #solidButton {
    width: 50%;
  }
  .check{
    padding:16px;
    border-radius: 100%;
    background-color:  	hsla(153, 47%, 49%, 0.15);
  }
  #registerComplete{

    @include theme(){
      background-color: theme-get('background-1');
    }

    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .info{
    padding:64px;
    gap:32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .verifyText{

      h1{
        margin:0;
        font-size: 4em;
        @include theme(){
          color: theme-get('header-1');
        }
        font-weight: 600;
      }
      div{

        @include theme(){
          color: theme-get('text-1');
        }

      }
      font-size: 1.5rem;
      @include theme(){
        color: theme-get('text-2');
      }

    }
    @media screen and ( max-width: 768px){
      .verifyText{
        h1{
          font-size: 2em;
        }

          font-size: 1.25em;

      }

    }
    @media screen and ( max-width: 480px){
      .info{
        padding: 32px;
      }
      .verifyText{
        h1{
          font-size: 2em;
        }

        font-size: 1em;

      }

    }
  }
</style>
