<template>
  <div id="splittedProgressBar">
    <div v-for="n in itemCount" class="points"  :key="'circle-'+n">
      <div class="pointBackground" :class="{'reached':currentItem>=n, 'material-icons':n===itemCount}" @click="pointFunction(n)">
        {{n!==itemCount?n:'check'}}
      </div>
    </div>
    <div id="progressBar" >
      <div :style="{'width':((currentItem-1)/(itemCount-1))*100+'%'}">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SplittedProgressbar",
  props:{
    itemFunction:Function,
    itemCount:Number,
    currentItem:Number
  },
  methods:{
    pointFunction(n){

      if(this.currentItem>n){
        this.itemFunction(n)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

@import "../../styles/themes.scss";
#splittedProgressBar {
  font-family: 'Poppins', sans-serif;
}



#progressBar{
  z-index: 49;
  position: absolute;
  width: 100%;
  height: 16px;
  border-radius: 16px;
  top:calc(50% - (16px/2));
  @include theme() {
    background-color: theme-get('primary-background');
  }
  div{
    transition: width 0.25s;

    @include theme() {
      background-color: theme-get('primary-color');
    }

    height: 16px;
    border-radius: 16px;
  }
}
#splittedProgressBar{
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.points{
  user-select: none;
  z-index: 50;
  position:relative;

  @include theme(){
    background-color: theme-get('background-color-2');
  }
  height: 40px;
  width: 40px;
  border-radius: 20px;
  .pointBackground {
    transition: background-color 0.25s;
    display: flex;
    justify-content: center;
    align-items: center;

    @include theme(){
      background-color: theme-get('primary-background');
      color: theme-get('primary-color')
    }

    margin: 4px;
    height: 32px;
    width: 32px;
    border-radius: 16px;

    &.reached{

      @include theme(){
        background-color: theme-get('primary-color');
      }
      color:#FFFFFF;
      cursor: pointer;
      &:hover{
        text-decoration: underline 2px;
      }
    }


  }

}
</style>
