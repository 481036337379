<template>
  <div class="socialmediacard">
    <div class="cardContent">
      <h4 id="title">{{ title }}</h4>
      <p>
        <slot></slot>
      </p>
    </div>

    <a id="urlButton" :href="url" :style="{'--socialTextColor':textColor}">
      <div id="buttonContainer" :style="{'--socialBGColor':color}">
        <div id="socialbutton">
          <img :src="require('@/assets/SocialMediaIcons/'+picture)" width="40" height="40">
          {{ buttonText }}
        </div>
      </div>
    </a>

  </div>
</template>

<script>

export default {
  name: "SocialMediaCard",
  props: {
    title: String,
    url: String,
    buttonText: String,
    picture: String,
    color: String,
    textColor: String
  },

  methods: {
    openUrl(link) {
      window.open(link, '_self')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/themes.scss";

#main {
  --color: rgba(54, 111, 222, 0.15);
  --textColor: rgba(54, 111, 222, 0.15);
}

.socialmediacard {
  padding: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  height: min-content;
  justify-content: space-between;
  border-radius: 16px;
  background-color: white;
  @include theme() {
    background-color: theme-get('background-color-1');
    box-shadow: theme-get('shadow-1');
  }
  a{
    text-decoration: none;
  }
}

h4 {
  font-size: 2em;
  font-weight: 600;
  margin: 0;
  @include theme() {
    color: theme-get('header-3')
  }
}

img {
}

#urlButton {
  color: var(--socialTextColor);
  width: 100%;
  transition: 0.25s;
  &:hover{
    transform: scale(1.02);
  }
}
#buttonContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: 600;
  width: 100%;
  padding: 8px;
  background-color: var(--socialBGColor);
}
#buttontext {

  color: var(--socialTextColor);
}

#buttontext:visited {
  color: var(--socialTextColor);
}


p {
  margin-top:0;
  @include theme() {
    color: theme-get('text-2');
  }
}


#socialbutton {
  padding:8px;
  gap:8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

</style>
