<template>
  <div class="tutorialCard">
    <div id="icon" class="material-icons">
      {{icon}}
    </div>
    <div class="cardContent">
      <h4 id="title-description">{{titleDescription}}</h4>
      <h4 id="title">{{title}}</h4>
      <p>
        <slot></slot>
      </p>
    </div>
    <PlainButton  :button-function="()=>(buttonFunction())" :scale-by-child="true">
      {{buttonText}}
    </PlainButton>
  </div>
</template>

<script>
import PlainButton from "@/components/Buttons/PlainButton";
export default {
  name: "TutorialCard",
  components: {PlainButton},
  props: {
    titleDescription: String,
    title: String,
    icon: String,
    buttonFunction:Function,
    buttonText: String
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/themes.scss";
.tutorialCard{
  padding: 48px;
  width:324px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height:min-content;
  border-radius:16px;


  @include theme(){
    background-color: theme-get('background-color-1');
    box-shadow: theme-get('shadow-1');
  }
  background-color: white;
}
#icon{
  @include theme(){
    background-color: theme-get('primary-background');
    color: theme-get('primary-color')
  }
  border-radius: 100%;
  padding: 16px;
  font-size: 48px;

}

h4{
  font-size:1.5em;
  margin:0;
  @include theme(){
    color:theme-get('header-3')
  }
}

#title {
  font-weight: 500;
}
#title-description{
  @include theme(){
    color: theme-get('primary-color');
  }
  font-weight: 600;
}
.cardContent{

  margin: 48px 0 16px 0;
}

p{
  @include theme(){
    color: theme-get('text-2');
  }
}


</style>