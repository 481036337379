<template>
  <div id="register">
    <h1>Registrieren</h1>
    <div class="registrationForm">
      <SplittedProgressbar :item-count=this.form.pages.length :current-item=form.currentItem
                           :item-function="goBackToNumber"/>
      <div class="form" v-if="form.pages[form.currentItem-1].fields!==undefined">
        <TextInput v-for="field in form.pages[form.currentItem-1].fields" :input-placeholder="field.placeholder"
                   class="textInput" :text-field-type="field.type" v-model:input-text="field.text"
                   :input-title="field.label" :validation-function="field.validFunction" :key="field.id" :ref="(el)=>setRegisterRefs(el,field.id)"
                   :do-validation="field.doValidation" :input-name="field.id"
                   :password-requirements="field.passwordRequirements"
                   :prevent-copy-paste="field.preventCopyPaste"/>
        <div class="buttons">
          <PlainButton :button-function="goBack" v-if="form.currentItem>1" button-type="fluid">Zurück</PlainButton>
          <PlainButton :button-function="next">Weiter</PlainButton>
        </div>

      </div>
      <div v-else class="finishedPage">

        <h2>Überprüfe deine Daten</h2>
        <div class="detail">
          <div>
            <p>Benutzername</p>
            <h3>{{ username }}</h3>
          </div>
          <div>
            <p>E-Mail</p>
            <h3>{{ email }}</h3>
          </div>
          <div v-if="discord.length>0">
            <p>Discord Name</p>
            <h3>{{ discord }}</h3>
          </div>
        </div>
        <div class="buttons">
          <PlainButton :button-function="goBack" button-type="fluid">Zurück</PlainButton>
          <PlainButton :button-function="()=>registerUser(user)">Fertig</PlainButton>
        </div>
      </div>
    </div>
    <transition name="error-show">
      <ErrorPop :error-text="errorMessage" v-if="errorType==='alert-danger'">
      </ErrorPop>
    </transition>
    <FullscreenLoading v-if="errorType==='alert-loading'"></FullscreenLoading>
  </div>
</template>

<script>
import TextInput from "@/components/Inputs/TextInput";
import SplittedProgressbar from "@/components/Indicators/SplittedProgressbar";
import {mapActions, mapState} from "vuex";
import ErrorPop from "@/components/Popups/ErrorPop";
import FullscreenLoading from "@/components/Indicators/Loading/FullscreenLoading";
import PlainButton from "@/components/Buttons/PlainButton";

export default {
  name: "Register",
  components: {PlainButton, FullscreenLoading, ErrorPop, SplittedProgressbar, TextInput},
  inheritAttrs: false,

  data() {
    return {
      refs:{},
      selectedPage: "",
      form: {
        currentItem: 1,
        pages: [
          {
            id: 'basicinformation',
            fields: [
              {
                label: 'Username',
                text: '',
                id: 'username',
                placeholder: 'GeilerWilli',
                preventCopyPaste:false,
                type: 'text',
                validFunction: function (text) {
                  if (text.length <= 2 || text.length === 0 || text.length >= 32) {
                    return {
                      isInvalid: true,
                      errorText: 'Benutzer zwischen 2 und 32 Zeichen'
                    }
                  } else if (!(/^[a-zA-Z0-9_.-]{2,32}$/.test(text))) {
                    return {
                      isInvalid: true,
                      errorText: 'keine Sonderzeichen ausser _.-'
                    }
                  } else {
                    return {
                      isInvalid: false,
                      errorText: ''
                    };
                  }
                },
                doValidation: true
              },
              {
                label: 'E-Mail',
                text: '',
                id: 'email',
                type: 'email',
                placeholder: 'geilerwilli@hallo.com',
                validFunction: function (text) {
                  return !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(text) || text.length === 0 ? {
                    isInvalid: true,
                    errorText: 'Keine E-Mail!'
                  } : {
                    isInvalid: false,
                    errorText: ''
                  };
                },
                doValidation: true,
                preventCopyPaste:false
              },
              {
                label: 'Discord(Optional)',
                text: '',
                id: 'discord',
                type: 'text',
                placeholder: 'GeilerWilli',
                validFunction(text) {
                  if (!/^(?!.*\.{2,})(?!.*[_.]{2,})[a-zA-Z0-9_.]{2,32}$/.test(text) && text.length > 0) {
                    return {
                      isInvalid: true,
                      errorText: 'Keine Discord Name'
                    }
                  } else {
                    return {
                      isInvalid: false,
                      errorText: ''
                    }
                  }
                },
                doValidation: true,
                preventCopyPaste:false
              }
            ],
          },
          {
            id: 'password',
            fields: [
              {
                label: 'Passwort',
                text: '',
                id: 'password',
                type: 'password',
                preventCopyPaste:true,
                passwordRequirements: [
                  {
                    id: 1, helpText: "Groß und Kleinbuchstaben (keine Umlaute)", validationFunction: (text) => {
                      return (/[a-z]/.test(text)) && (/[A-Z]/.test(text))
                    }
                  },
                  {
                    id: 2, helpText: "Min. 8 Zeichen", validationFunction: (text) => {
                      return text.length >= 8
                    }
                  },
                  {
                    id: 3, helpText: "Ein Sonderzeichen", validationFunction: (text) => {
                      return (/[^\w]/.test(text))
                    }
                  },
                  {
                    id: 4, helpText: "Eine Zahl", validationFunction: (text) => {
                      return (/\d/.test(text))
                    }
                  }
                ],
                validFunction: function (text) {
                  if (text.length < 8 || text.length === 0) {
                    return {
                      isInvalid: true,
                      errorText: 'Passwort zu kurz'
                    }
                  } else if (!(/\d/.test(text))) {
                    return {
                      isInvalid: true,
                      errorText: 'Passwort muss eine Zahl beinhalten'
                    }
                  } else if (!(/[a-z]/.test(text)) || !(/[A-Z]/.test(text))) {
                    return {
                      isInvalid: true,
                      errorText: 'Passwort muss einen Groß- und einen Kleinbuchstaben beinhalten'
                    }
                  } else if (!(/[^w]/.test(text))) {
                    return {
                      isInvalid: true,
                      errorText: 'Passwort muss ein Sonderzeichen beinhalten'
                    }
                  } else {
                    return {
                      isInvalid: false,
                      errorText: ''
                    };
                  }
                },
                doValidation: true
              },
              {
                label: 'Passwort wiederholen',
                text: '',
                id: 'password',
                type: 'password',
                validFunction: (text) => {
                  return !(this.password === text) ?
                      {
                        isInvalid: true,
                        errorText: 'Passwort muss übereinstimmen'
                      } : {
                        isInvalid: false,
                        errorText: ''
                      }
                },
                doValidation: true,
                preventCopyPaste:true
              },
            ]
          },
          {
            id: 'validateaccount',
          }
        ]
      },
    }
  },
  computed: {
    ...mapState('alert', {
          errorMessage: 'message',
          errorType: 'type'
        }
    ),

    user() {
      return {
        'user_name': this.username,
        'email': this.email,
        'password': this.password,
        'discord_name': this.discord.length > 0 ? this.discord : null
      }
    },
    username() {
      return this.form.pages.find(value => value.id === 'basicinformation').fields.find(value => value.id === 'username').text
    },
    email() {
      return this.form.pages.find(value => value.id === 'basicinformation').fields.find(value => value.id === 'email').text
    },
    discord() {
      return this.form.pages.find(value => value.id === 'basicinformation').fields.find(value => value.id === 'discord').text
    },
    password() {
      return this.form.pages.find(value => value.id === 'password').fields.find(value => value.id === 'password').text
    }
  },
  methods: {
    ...mapActions("account", ['register']),
    setRegisterRefs(el, name){
      if(el){
        this.refs[name]=el;
      }
    },
    next() {
      let formContext = this;

      function validateTexts() {
        let isValid = true;
        formContext.form.pages[formContext.form.currentItem - 1].fields.forEach(field => {
          formContext.refs[field.id].validate(field.text)
          isValid = !formContext.refs[field.id].isInvalid && isValid;
        });
        return isValid;
      }

      if (this.form.currentItem < this.form.pages.length && validateTexts()) {
        this.form.currentItem++;
      }

    },
    goBackToNumber(number) {

      if (number > 0 && this.form.currentItem > 1) {
        this.form.currentItem = number;
      }
    },

    goBack() {
      if (this.form.currentItem > 1) {
        this.form.currentItem--;
      }
    },
    registerUser() {
      this.register(this.user)
    }
  },


}
</script>

<style lang="scss" scoped>
@import "src/styles/themes.scss";

.finishedPage {
  font-family: 'Poppins', sans-serif;

  .buttons {
    width: 100%;
    gap: 12px;
    flex-direction: row;
  }

  h2 {
    font-size: 3em;
    font-weight: 600;
    @include theme() {
      color: theme-get('header-3')
    }

    margin-top: 36px;
    bottom: 32px;
  }

  .detail {

    margin-bottom: 32px;
  }

  div {
    margin-bottom: 16px;

    p {
      color: #6a6a6a;
      margin: 0;
      text-transform: uppercase;
    }

    h3 {
      font-size: 24px;
      @include theme() {
        color: theme-get('text-1')
      }
      color: #444444;
      margin: 0;
      font-weight: 600;
    }

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
  }

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}

.error-show-enter-active, .error-show-leave-active {
  transition: all 0.25s;
}

.error-show-enter-from,
.error-show-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

#register {
  @include theme() {
    background-color: theme-get('background-color-2');
  }
  position: relative;
  min-height: 100%;
  height: auto;
  z-index: 50;
  padding: 64px 20%;

  h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    margin-bottom: 16px;
    font-size: 5em;
    text-align: left;
    width: auto;
    @include theme() {
      color: theme-get('header-1')
    }

  }
}

.registrationForm {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  margin-top: 56px;

  .buttons {
    width: 100%;
    margin-top: 32px;
    display: flex;
    flex-direction: column-reverse;
    align-self: center;
    gap: 8px;
  }
}

.textInput {
  width: 300px;
}

@media screen and (max-width: 1200px) {
  #register {
    padding: 64px 64px 64px 64px;
  }

}

@media screen and (max-width: 768px) {
  .error-show-enter-from,
  .error-show-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }
  #register {
    h1 {
      text-align: center;
      font-size: 3em;
    }
  }
  .finishedPage {
    h2 {
      font-size: 2em;
    }
  }

}

@media screen and (max-width: 480px) {
  #register {
    padding: 64px 32px 32px 32px;

    h1 {
      margin-top: 48px;
      text-align: center;
      font-size: 2.25em;
    }

    .textInput {
      width: 100%;
    }

    label {
      text-align: left;
    }


  }

  .buttons {

    flex-direction: column-reverse;
    align-items: center;
    width: 100%;

    div {
      order: -1;
    }
  }
  .form {
    margin-top: 32px;
  }
  .buttons {
    margin-top: 32px;
  }

  .finishedPage {
    .buttons {
      flex-direction: column-reverse;
      align-items: center;
      width: 100%;

      div {
        order: -1;
      }
    }
  }
}

</style>
