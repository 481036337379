<template>
  <div id="notfound"><h1>404</h1>
    <div>
      <p>Du bist komisch. Ich weiß nicht, wo du hin wolltest.</p>
    </div>
    <PlainButton  :button-function="navigateHome">
      Zurück nach Hause
    </PlainButton>
  </div>
</template>

<script>
import PlainButton from "@/components/Buttons/PlainButton";
export default {
  inheritAttrs:false,
  name: "Notfound",
  components: {PlainButton},
  methods:{
    navigateHome(){
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/themes.scss";
  #solidButton{
    margin-top: 72px;
    width: auto;
  }
  #notfound{
    padding:64px;
    display: flex;
    flex-direction: column;
    height: 100%;

    justify-content: center;
    align-items: center;
    @include theme (){
      background-color: theme-get('background-color-1');
      color: theme-get('header-1')
    }
    h1{
      margin: 0;
      font-size: 10rem;
    }
    p{
      @include theme (){
        color: theme-get('header-3')
      }
      font-size: 1.5rem;
      margin:0;
    }
  }
</style>
