<template>
  <div id="profileEditCard">
    <h2>Deine Daten</h2>
    <TextInput
        :input-title="field.label"
        :input-placeholder="field.placeholder"
        :text-field-type="field.type"
        :validation-function="field.validFunction"
        :do-validation="field.doValidation"
        v-for="field in fields"
        :key="field.id"
        v-model:input-text="field.text"
        :ref="(el)=>setProfileRefs(el,field.id)"
    >
    </TextInput>
    <SolidButton :button-function="()=>{submitUpdate(userObject)}">Daten ändern</SolidButton>
  </div>
</template>

<script>
import TextInput from "../Inputs/TextInput";
import SolidButton from "../Buttons/PlainButton";
import {mapActions, mapState} from "vuex";

export default {
  name: "ProfileEditCard",
  components: {SolidButton, TextInput},
  props:{
    usernameProp:String,
    emailProp:String,
    discordProp:String,
  },
  computed:{
    ...mapState('account',['user']),
    userObject(){
      let userObject={}
      this.username !== this.usernameProp? userObject["user_name"]=this.username :{}
      this.email !== this.emailProp? userObject["email"]=this.email :{}
      this.discord !== this.discordProp ? userObject["discord_name"]=this.discord : {}
      return userObject
    },
    username(){return this.fields.find(value => value.id==='username').text},
    email(){return this.fields.find(value => value.id==='email').text},
    discord(){return this.fields.find(value => value.id==='discord').text}
  },
  data() {
    return {
      refs:{},
      fields: [
        {
          label: 'Username',
          text: this.usernameProp,
          id: 'username',
          placeholder: 'GeilerWilli',
          type: 'text',
          validFunction: function (text) {
            if (text?.length <= 2 || text?.length === 0 || text?.length>=32) {
              return {
                isInvalid: true,
                errorText: 'Benutzer zwischen 2 und 32 Zeichen'
              }
            }else if(!(/^[a-zA-Z0-9_.-]{2,32}$/.test(text))){
              return {
                isInvalid: true,
                errorText: 'keine Sonderzeichen ausser _.-'
              }
            } else {
              return {
                isInvalid: false,
                errorText: ''
              };
            }
          },
          doValidation: true
        },
        {
          label: 'E-Mail',
          text: this.emailProp,
          id: 'email',
          type: 'email',
          placeholder: 'geilerwilli@hallo.com',
          validFunction: function (text) {
            return !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(text) || text.length === 0 ? {
              isInvalid: true,
              errorText: 'Keine E-Mail!'
            } : {
              isInvalid: false,
              errorText: ''
            };
          },
          doValidation: true
        },
        {
          label: 'Discord Name',
          text: this.discordProp,
          id: 'discord',
          type: 'text',
          placeholder: 'GeilerWilli#1234',
          validFunction(text) {
            return !/^[a-zA-Z0-9_.-]{2,32}$/.test(text) && text.length > 0 ? {
              isInvalid: true,
              errorText: 'Keine Discord Name'
            } : {
              isInvalid: false,
              errorText: ''
            };
          },
          doValidation: true
        }

      ],
    }

  },
  watch:{
    usernameProp(newUserName){
      this.fields.find(value => value.id==='username').text=newUserName;
    },
    emailProp(newEmail){
      this.fields.find(value => value.id==='email').text=newEmail;
    },
    discordProp(newDiscordName){
      this.fields.find(value => value.id==='discord').text=newDiscordName;
    }
  },
  methods:{
    ...mapActions("account",["update"]),
    setProfileRefs(el, name){
      if(el){
        this.refs[name]=el;
      }
    },
    submitUpdate(){

      let formcontext=this;
      function validateTexts(){
        let isValid = true;
        formcontext.fields.forEach(field => {
          formcontext.refs[field.id].validate(field.text)
          isValid = !formcontext.refs[field.id].isInvalid && isValid;
        });
        return isValid;
      }
      if(validateTexts()){
        this.update(this.userObject)
      }
    },
  }
}
</script>

<style lang="scss" scoped>

@import 'src/styles/themes';


#profileEditCard {

  h2 {
    text-align: left;
    font-size: 3em;
    @include theme(){
      color:theme-get('header-2')
    }
    font-weight: 600;
    margin: 0 0 16px;
  }
  max-width: 500px;
  padding: 24px;
}

@media screen and(max-width: 768px){
  #profileEditCard{
    max-width: 100%;
    h2{
      font-size: 2.5em;
    }
  }
}

</style>
