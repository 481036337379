<template>
  <div id="login">
    <h1>Login</h1>
    <div class="formInputs">
      <div class="textInputs">
        <TextInput v-for="field in fields" :input-placeholder="field.placeholder" v-model:input-text="field.text"
                   :text-field-type="field.type" :validation-function="field.validFunction" :input-title="field.label"
                   :key="field.id" :do-validation="field.useValidation" :ref="(el)=>setLoginRefs(el,field.id)" :show-password-reset-link="field.showPWReset">
        </TextInput>
      </div>
      <div class="actions">
        <PlainButton :button-function="submitLogin">Anmelden</PlainButton>
        <router-link to="/register">Registrieren</router-link>
      </div>
      <FullscreenLoading v-if="errorType==='alert-loading'"/>
    </div>
    <transition name="error-show">
      <ErrorPop :error-text="errorMessage" v-if="errorType==='alert-danger'">
      </ErrorPop>
    </transition>
  </div>
</template>

<script>
import TextInput from "@/components/Inputs/TextInput";
import {mapActions, mapState} from "vuex";
import ErrorPop from "@/components/Popups/ErrorPop";
import FullscreenLoading from "@/components/Indicators/Loading/FullscreenLoading";
import PlainButton from "@/components/Buttons/PlainButton";

export default {
  name: "Login",
  components: {PlainButton, FullscreenLoading, TextInput, ErrorPop},
  inheritAttrs:false,
  computed:{
    email(){return this.fields.find(value => value.id==='email').text},
    password(){return this.fields.find(value => value.id==='password').text},
    ...mapState('alert', {
      errorMessage: 'message',
      errorType: 'type'
    })
  },

  methods:{
    ...mapActions('account',['login']),
    ...mapActions('alert',['clear']),

    setLoginRefs(el, name){
      if(el){
        this.refs[name]=el;
      }
    },
    submitLogin(){
      let formcontext=this;
      function validateTexts(){

        let isValid = true;
        formcontext.fields.forEach(field => {


          formcontext.refs[field.id]?.validate(field.text)
          isValid = !formcontext.refs[field.id]?.isInvalid && isValid;
        });
        return isValid;
      }
      if(validateTexts()){
        let email=this.email
        let password=this.password
        this.login({email,password})
      }


    },
    validateTexts() {
      let isValid = true;
      this.fields.forEach(field => {
        this.refs[field.id].validate(field.text)
        isValid = !this.refs[field.id].isInvalid && isValid;
      });
      return isValid;
    }
  },
  data() {
    return {
      refs:{},
      fields: [
        {
          label: 'Username oder E-mail',
          text: '',
          id: 'email',
          placeholder: 'Benutzer',
          type: 'email',
          showPWReset: false,
          validFunction: function (text) {
            return (text.length===0)?{
              isInvalid: true,
              errorText: 'Username oder Email eingeben'
            } : {
              isInvalid: false,
              errorText: ''
            };
          },
          useValidation: true
        },
        {
          label: 'Passwort',
          text: '',
          id: 'password',
          placeholder: '',
          type: 'password',
          showPWReset: true,
          validFunction(text) {
            return text.length === 0 ? {
              isInvalid: true,
              errorText: 'Passwort eingeben'
            } : {
              isInvalid: false,
              errorText: ''
            };
          },
          useValidation: true
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/themes.scss";

h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 4em;
  @include theme(){
    color: theme-get('header-1')
  }
  color: #202020;
  margin:0;
}
.textInputs{
  margin-bottom: 72px;
}

.error-show-enter-active, .error-show-leave-active{
  transition: all 0.25s;
}
.error-show-enter-from,
.error-show-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
.formInputs{

}
.actions{
  box-sizing: border-box;
  margin-top:32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap:16px;
  a{
    @include theme(){
      color: theme-get('text-2');
    }
    &:visited{
      text-decoration: none;

      @include theme(){
        color: theme-get('text-2');
      }
    }
    &:active{
      text-decoration: none;
      @include theme(){
        color: theme-get('text-2');
      }
    }
    &:hover{
      @include theme(){
        color: theme-get('text-1');
      }

    }
  }
}
#login {
  @include theme(){
    background-color: theme-get('background-color-2');
  }
  padding:0 32px;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:16px;
  flex-direction: column;
}
</style>
